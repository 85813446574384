import React, { useState, useEffect } from "react";
import BookAppointment from "./AppointmentBooking";
import AppointmentList from "./AppointmentList";
import "../../assets/css/ReceptionAppointmentManagement.css";
import { useAuth } from "../../context/AuthContext"; 
// import baseURL from "../../config";

const AppointmentManagement = () => {
  const [view, setView] = useState("buttons");
  const [headView, setHeadView] = useState(true);
  const { user } = useAuth();
  const userId = user?.user_id;
  const [hospitalId, setHospitalId] = useState(userId); // State to hold receptionist's hospital ID

  

  return (
    <div>
       <div className="img-container">
      <div className="text-center">
        {headView && <h1 className="head">Appointment Management</h1>}

        <div>
          {view === "buttons" ? (
            <>
              <button
                onClick={() => {
                  setView("book");
                  setHeadView(false);
                }}
                className="appointment-buttons btn btn-primary mr-2"
              >
                Book Appointment
              </button>
              <button
                onClick={() => {
                  setView("list");
                  setHeadView(false);
                }}
                className="appointment-buttons btn btn-secondary"
              >
                Appointment List
              </button>
            </>
          ) : (
            <div className="back-button-alignment">
              <button
                onClick={() => {
                  setView("buttons");
                  setHeadView(true);
                }}
                className="btn"
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          )}
        </div>
      </div>
      <div>
        {view === "book" && <BookAppointment hospitalId={hospitalId} />}
        {view === 'list' && <AppointmentList hospitalId={hospitalId} />}
      </div>
    </div>
    </div>
   
  );
};

export default AppointmentManagement;

import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";

const AppointmentList = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark", // Switches the palette to dark mode
    },
  });

  const [appointments, setAppointments] = useState([]);
  const { user } = useAuth();
  const userId = user?.user_id;
  const [hospitalId, setHospitalId] = useState(null);

  useEffect(() => {
    if (userId) {
      fetchHospitalId(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (hospitalId) {
      fetchAppointments(hospitalId);
    }
  }, [hospitalId]);

  const fetchHospitalId = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/user/getHospitalId/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch hospital ID");
      }
      const data = await response.json();
      setHospitalId(data.hospital_id);
      console.log("Fetched hospital ID:", data.hospital_id);
    } catch (error) {
      console.error("Error fetching hospital ID:", error);
    }
  };

  const fetchAppointments = async (hospitalId) => {
    try {
      const response = await fetch(`${baseURL}/user/getConsultedAppointments/${hospitalId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const formattedData = data.map((appointment) => ({
        ...appointment,
        consulted_Date: new Date(appointment.consulted_Date).toLocaleDateString("en-GB"), // Format date to day/month/year.
      }));
      setAppointments(formattedData);
      console.log("Fetched appointments:", formattedData);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const columns = [
    { accessorKey: "created_id", header: "Patient Id", size: 80 },
    { accessorKey: "b_name", header: "Name", size: 80, 
      Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
    { accessorKey: "consulted_Date", header: "Consulted Date", size: 80 },
    { accessorKey: "consulted_Time", header: "Consulted Time", size: 80 },
    { accessorKey: "b_appointment_type", header: "Type", size: 80,
       Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
    { accessorKey: "b_status", header: "Status", size: 80,
       Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
  ];

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <MaterialReactTable columns={columns} data={appointments} />
      </ThemeProvider>
    </div>
  );
};

export default AppointmentList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Modal, Pagination } from "react-bootstrap";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import baseURL from "../../config";
import "../../assets/css/ViewReport.css";
import { useAuth } from "../../context/AuthContext";

const MedicalReports = () => {
 
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  const [data, setData] = useState([]);
  const [hospitalId, setHospitalId] = useState(null);
  const { user } = useAuth();
  const userId = user?.user_id;

  
  useEffect(() => {
    fetchData(userId);
  }, []);



  const fetchData = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/doctor/patients/${userId}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const filtered = data.filter((item) => {
      const patientName = item.p_name
        ? item.p_name.toLowerCase()
        : "";
      const doctorName = item.p_id ? item.p_id.toString() : "";
      const date = item.date ? item.date.toLowerCase() : "";
      return (
        patientName.includes(searchTerm.toLowerCase()) ||
        doctorName.includes(searchTerm.toLowerCase()) ||
        date.includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered);
  }, [searchTerm, data]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDownload = (Id) => {
    axios.get(`${baseURL}/doctor/download/${Id}`, { responseType: 'blob' })
      .then(response => {
        console.log('Response data size:', response.data.size);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MedicalReport.pdf'); // Specify the filename
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error('There was an error downloading the file!', error);
      });
  };
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="modified">
    <div className="hospital-table-container">
      <Container>
        <Form.Group controlId="search" className="mb-4">
          <Form.Control
            type="text"
            placeholder="Search by Patient, Doctor, or Date..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Form.Group>
        <table className="hospital-table">
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>Patient Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Disease</th>
              <th>Reports</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.p_id}</td>
                <td>{item.p_name}</td>
                <td>{item.p_age}</td>
                <td>{item.p_gender}</td>
                <td>{item.p_disease}</td>
                <td>
                  <Button className=" btn btn-primary"
                    onClick={() => handleDownload(item.p_id)}
                  >
                    Download Pdf
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination>
          {Array.from(
            { length: Math.ceil(filteredData.length / itemsPerPage) },
            (_, index) => (
              <Pagination.Item
                key={index + 1}
                onClick={() => paginate(index + 1)}
                active={index + 1 === currentPage}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>

        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>PDF Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={currentPdfUrl} />
            </Worker>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
    </div>
  );
};

export default MedicalReports;

import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from "../config";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/protected`, { withCredentials: true });
        setUser(response.data.user);
        console.log('Authentication successful');
      } catch (error) {
        console.log('Not authenticated:', error);
      }
      setLoading(false);
    };

    verifyUser();
  }, []);

  const login = async (user_email, user_password) => {
    try {
      const response = await axios.post(`${baseURL}/api/login`, { user_email, user_password }, { withCredentials: true });
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${baseURL}/api/logout`, {}, { withCredentials: true });
      setUser(null);
    } catch (error) {
      console.log('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

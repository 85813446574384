import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import "../../assets/css/AppointBooking.css";
import baseURL from "../../config";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppointmentBooking = () => {
  const initialFormData = {
    b_name: "",
    b_age: "",
    b_email: "",
    b_date_of_appointment: "",
    b_time_of_appointment: "",
    b_hospital_id: "",
    b_appointment_type: "",
    b_address: "",
    b_mobile: "",
    specialization: "",
    b_d_id: "",
  };

  const { user } = useAuth(); // Assume useAuth returns the logged-in user
  const userId = user?.user_id;

  const [formData, setFormData] = useState(initialFormData);
  const [hospitals, setHospitals] = useState([]);
  const [errors, setErrors] = useState({});
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    error: "",
  });
  const [doctors, setDoctors] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);

  useEffect(() => {
    fetchHospitalNames();
    fetchDoctorsNames();
  }, []);

  const fetchHospitalNames = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/hospitalNames`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHospitals(data);
    } catch (error) {
      console.error("Error fetching hospital names:", error);
    }
  };

  const fetchDoctorsNames = async () => {
    try {
      const response = await fetch(`${baseURL}/user/doctorNames`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDoctors(data);
    } catch (error) {
      console.log("Error fetching doctor names:", error);
    }
  };

  const fetchSpecializationsByHospital = async (hospitalId) => {
    try {
    
      const response = await fetch(`${baseURL}/user/specializations/${hospitalId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSpecializations(data);
      
    } catch (error) {
      console.error("Error fetching specializations:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${baseURL}/user/bookAppointments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, created_id: userId }),
      });

      if (response.ok) {
        console.log("Form data submitted successfully");
        toast.success("Appointment Booked Successfully!");
        setFormData(initialFormData);
        setFormStatus({ submitted: true, error: "" });
      } else {
        throw new Error(`Failed to submit form data: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      setFormStatus({ submitted: false, error: error.message });
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setSpecializations([]);
    setFormStatus({ submitted: false, error: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  
    // Clear previous errors for the current field
    setErrors({
      ...errors,
      [name]: "",
    });
  
    // Perform individual field validations
    if (name === "b_email") {
      validateEmail(value);
    } else if (name === "b_mobile") {
      validateMobileNumber(value);
    }
  
    // Fetch specializations and reset filtered doctors when a hospital is selected
    if (name === "b_hospital_id") {
      setSpecializations([]);
      setFilteredDoctors([]);
      fetchSpecializationsByHospital(value);
      setFormData({
        ...formData,
        [name]: value,
        specialization: "", // Reset specialization
        b_d_id: "" // Reset selected doctor
      });
    }
  
    // Filter doctors when a specialization is selected
    if (name === "specialization") {
      const filtered = doctors.filter((doctor) => doctor.d_specialization === value && doctor.d_hospital_id === formData.b_hospital_id);
      setFilteredDoctors(filtered);
      setFormData({
        ...formData,
        [name]: value,
        b_d_id: "" // Reset selected doctor
      });
    }
  };
  

  const validateEmail = (email) => {
    if (!email.endsWith("@gmail.com")) {
      setErrors({
        ...errors,
        b_email: "Please enter a valid Gmail address.",
      });
    } else {
      setErrors({
        ...errors,
        b_email: "",
      });
    }
  };

  const validateMobileNumber = (mobileNumber) => {
    if (!/^[6-9]\d{9}$/.test(mobileNumber)) {
      setErrors({
        ...errors,
        b_mobile: "Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.",
      });
    } else {
      setErrors({
        ...errors,
        b_mobile: "",
      });
    }
  };

  const validateForm = () => {
    let valid = true;

    // Validate email
    if (!formData.b_email.endsWith("@gmail.com")) {
      setErrors({
        ...errors,
        b_email: "Please enter a valid Gmail address.",
      });
      valid = false;
    }

    // Validate mobile number
    if (!/^[6-9]\d{9}$/.test(formData.b_mobile)) {
      setErrors({
        ...errors,
        b_mobile: "Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.",
      });
      valid = false;
    }

    return valid;
  };

  function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  return (
    <div>
      <Card className="Form form-main">
        <div className="div-1 d-flex">
          <div className="heading float-start">
            <h3 className="heading-2">Appointment Details</h3>
          </div>
        </div>
        <Form onSubmit={handleSubmit} className="pt-3">
          <Row>
            <Col md={6} xs={12}>
              <Form.Group as={Row} controlId="first_name" className="mb-3">
                <Form.Label column md={4}>
                  Full Name
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    name="b_name"
                    placeholder="Full Name"                    required
                    value={formData.b_name}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="age" className="mb-3">
                <Form.Label column md={4}>
                  Age
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    name="b_age"
                    type="text"
                    placeholder="Age"
                    value={formData.b_age}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="mobile_no" className="mb-3">
                <Form.Label column md={4}>
                  Phone Number
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    name="b_mobile"
                    type="tel"
                    placeholder="Phone Number"
                    value={formData.b_mobile}
                    onChange={handleChange}
                    isInvalid={!!errors.b_mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.b_mobile}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="date_of_appointment"
                className="mb-3"
              >
                <Form.Label column md={4}>
                  Booking Date
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    type="date"
                    name="b_date_of_appointment"
                    required
                    min={getCurrentDate()}
                    value={formData.b_date_of_appointment}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="time_of_appointment"
                className="mb-3"
              >
                <Form.Label column md={4}>
                  Booking Time
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    type="time"
                    name="b_time_of_appointment"
                    required
                    value={formData.b_time_of_appointment}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="hospital_name" className="mb-3">
                <Form.Label column md={4}>
                  Select Hospital
                </Form.Label>
                <Col md={8}>
                  <Form.Select
                    name="b_hospital_id"
                    required
                    value={formData.b_hospital_id}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>
                      Select Your Hospital
                    </option>
                    {hospitals.map((hospital) => (
                      <option
                        key={hospital.hospital_id}
                        value={hospital.hospital_id}
                      >
                        {hospital.hospital_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group as={Row} controlId="specialization">
                <Form.Label column md={4}>
                  Select Specialization
                </Form.Label>
                <Col md={8}>
                  <Form.Select
                    name="specialization"
                    required
                    value={formData.specialization}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>
                      Select Specialization
                    </option>
                    {specializations.map((specialization, index) => (
                      <option key={index} value={specialization.d_specialization}>
                        {specialization.d_specialization}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="doctor_id" className="mb-3">
                <Form.Label column md={4}>
                  Doctor Name
                </Form.Label>
                <Col md={8}>
                  <Form.Select
                    name="b_d_id"
                    required
                    value={formData.b_d_id}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>
                      Select Doctor Name
                    </option>
                    {filteredDoctors.map((doctor) => (
                      <option key={doctor.d_id} value={doctor.d_id}>
                        {doctor.d_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <br />
              <Form.Group
                as={Row}
                controlId="appointment_type"
                className="mb-3"
              >
                <Form.Label column md={4}>
                  Booking Type
                </Form.Label>
                <Col md={8}>
                  <Form.Select
                    name="b_appointment_type"
                    required
                    value={formData.b_appointment_type}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>
                      Select Booking Type
                    </option>
                    <option value="emergency">Emergency Appointment</option>
                    <option value="basic">Basic Appointment</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="address" className="mb-3">
                <Form.Label column md={4}>
                  Address
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    name="b_address"
                    placeholder="Address"
                    required
                    value={formData.b_address}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="email" className="mb-3">
                <Form.Label column md={4}>
                  Email
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    className="formc"
                    name="b_email"
                    type="email"
                    placeholder="Email"
                    required
                    value={formData.b_email}
                    onChange={handleChange}
                    isInvalid={!!errors.b_email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.b_email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-end pb-5 pr-5">
              <Button className="me-2" type="submit">
                Submit
              </Button>
              <Button
                className="ms-3"
                variant="danger"
                type="button"
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
          </Row>
        </Form>
        <ToastContainer className="mt-5" />
      </Card>
    </div>
  );
};

export default AppointmentBooking;

import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import {
  FaHospital,
  FaUserDoctor,
  FaUserInjured,
  FaFileMedical,
  FaDroplet,
  FaPills,
  FaArrowLeft,
} from "react-icons/fa6";
import TotalHospitals from "../SuperAdmin/AllHospital";
import TotalDoctors from "../SuperAdmin/AllDoctors";
import TotalStaff from "../SuperAdmin/AllStaff";
import TotalPatients from "../SuperAdmin/AllPatients";
import "../../assets/css/Dashboard.css";
import baseURL from "../../config";

const CardContainer = styled(Card)`
  && {
    border-radius: 1rem;
    margin-bottom: 1rem;
    background-color: #edeceb;
    border: none;
    padding-top: 0.5rem;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    .card-header {
      background-color: #1d212a;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-radius: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      svg {
        margin-right: 0.5rem;
      }
    }

    .card-body {
      padding: 2rem;
      padding-left: 2rem;

      h5 {
        font-size: 1.8rem;
        font-weight: bold;
        color: #3f51b5;
      }
    }
  }
`;

const Dashboard = ({ handleCardClick, handleBackClick, isCardClicked }) => {
  const [counts, setCounts] = useState({
    totalPatients: 0,
    totalDoctors: 0,
    totalHospitals: 0,
    totalStaff: 0,
  });

  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/superUser/dashboard`);
        setCounts(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCardClickInternal = (cardKey) => {
    setActiveCard(cardKey);
    handleCardClick(); // Notify parent component
  };

  const handleBackClickInternal = () => {
    setActiveCard(null);
    handleBackClick(); // Notify parent component
  };

  const cards = [
    {
      key: "totalHospitals",
      label: "Total Hospitals",
      icon: FaHospital,
      count: counts.totalHospitals,
      content: <TotalHospitals />,
    },

    {
      key: "totalDoctors",
      label: "Total Doctors",
      icon: FaUserDoctor,
      count: counts.totalDoctors,
      content: <TotalDoctors />,
    },

    {
      key: "nurseStaff",
      label: "Total Nurse/Staff",
      icon: FaFileMedical,
      count: counts.totalStaff,
      content: <TotalStaff />,
    },

    {
      key: "totalPatients",
      label: "Total Patients",
      icon: FaUserInjured,
      count: counts.totalPatients,
      content: <TotalPatients />,
    },

    {
      key: "bloodDonors",
      label: "Total Blood Donors",
      icon: FaDroplet,
      count: "Fetching...",
      content: <div>Blood Donors content here</div>,
    },

    {
      key: "totalPharmacy",
      label: "Total Pharmacy's",
      icon: FaPills,
      count: "Fetching...",
      content: <div>Pharmacy content here</div>,
    },
  ];

  return (
    <div className="dot">
      <div className="container">
        {activeCard === null ? (
          <div className="pt-4 row spy">
            {cards.map((card) => (
              <div className="col-md-4 pt-2" key={card.key}>
                <CardContainer onClick={() => handleCardClickInternal(card.key)}>
                  <Card.Header>
                    <card.icon size={30} />
                    {card.label}
                  </Card.Header>
                  <Card.Body>
                    <h5>{card.count}</h5>
                  </Card.Body>
                </CardContainer>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Button
              variant="link"
              onClick={handleBackClickInternal}
              style={{
                paddingTop: "20px",
                color: "#dfcd84",
                paddingRight: "980px",
              }}
            >
              <FaArrowLeft size={30} />
            </Button>
            <div className="mainContent">
              {cards.map(
                (card) =>
                  activeCard === card.key && (
                    <div key={card.key}>{card.content}</div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

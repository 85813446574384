// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import "../assets/css/RegisterModal.css";
// import baseURL from "../config";

// const ProfileModal = ({ show, onHide, user }) => {
//   // console.log(user.user_id);
//   const [users, setUsers] = useState({
//     user_name: "",
//     user_email: "",
//     user_password: "",
//     user_mobile: "", // Add id field to state
//   });
//   useEffect(() => {
//     if (user) {
//       setUsers({
//         user_name: user.user_name || "",
//         user_email: user.user_email || "",
//         user_password: user.user_password || "",
//         user_mobile: user.user_mobile || "",
//       });
//     }
//   }, [user]);

//   const handleChange = (e) => {
//     setUsers({ ...users, [e.target.name]: e.target.value });
//   };

//   const handleUpdate = async (id) => {
//     try {
//       const response = await fetch(`${baseURL}/admin/users/${id}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           user_name: users.user_name,
//           user_email: users.user_email,
//           user_password: users.user_password,
//           user_mobile: users.user_mobile,
//         }),
//       });
//       if (response.ok) {
//         onHide();
//         console.log("Successfully updated data");
//       } else {
//         console.error("Failed to update data:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error updating data:", error);
//     }
//   };

//   return (
//     <Modal show={show} onHide={onHide} centered>
//       <Form onSubmit={(e) => handleUpdate(user.id)}>
//         <Modal.Header closeButton>
//           <Modal.Title >Update Profile</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* Form fields */}
//           <Form.Group className="mb-3" controlId="user_name">
//             <Form.Label>User Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter FullName"
//               name="user_name"
//               value={users.user_name}
//               onChange={handleChange}
//               required
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" controlId="user_email">
//             <Form.Label>Email</Form.Label>
//             <Form.Control
//               type="email"
//               placeholder="Enter Email"
//               name="user_email"
//               value={users.user_email}
//               onChange={handleChange}
//               required
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" controlId="user_password">
//             <Form.Label>Password</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter Email"
//               name="user_password"
//               value={users.user_password}
//               onChange={handleChange}
//               required
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" controlId="user_mobile">
//             <Form.Label>Mobile</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter Mobile Number"
//               name="user_mobile"
//               value={users.user_mobile}
//               onChange={handleChange}
//               required
//             />
//           </Form.Group>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={onHide}>
//             Close
//           </Button>
//           <Button type="submit" variant="primary">
//             Update
//           </Button>
//         </Modal.Footer>
//       </Form>
//     </Modal>
//   );
// };

// export default ProfileModal;

import React, { useState, useEffect } from "react";
import { Modal, Button, TextField, Grid, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import baseURL from "../config";

const CustomModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CustomForm = styled('form')({
  width: 400,
  backgroundColor: '#1d212a',
  padding: '20px',
  borderRadius: '10px',
  color: '#f0f0f0', // Body text color
});

const CustomModalHeader = styled('div')({
  borderBottom: '1px solid #e9ecef',
  marginBottom: '15px',
  paddingBottom: '10px',
});

const CustomModalTitle = styled('h2')({
  color: '#dfcd84',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  margin: 0,
});

const CustomFormControl = styled(TextField)({
  width: '100%',
  marginBottom: '15px',
  '& .MuiInputBase-input': {
    color: '#f0f0f0', // Input text color
  },
  '& .MuiInputLabel-root': {
    color: '#ccc', // Label text color
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#555', // Outline border color
  },
});

const CustomModalFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
});

const CustomPrimaryButton = styled(Button)({
  backgroundColor: '#007bff',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const CustomSecondaryButton = styled(Button)({
  backgroundColor: '#6c757d',
  color: '#fff',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: '#5a6268',
  },
});

const ProfileModal = ({ show, onHide, user }) => {
  const [users, setUsers] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
    user_mobile: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      setUsers({
        user_name: user.user_name || "",
        user_email: user.user_email || "",
        user_password: user.user_password || "",
        user_mobile: user.user_mobile || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setUsers({ ...users, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (id) => {
    try {
      const response = await fetch(`${baseURL}/admin/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(users),
      });
      if (response.ok) {
        onHide();
        console.log("Successfully updated data");
      } else {
        console.error("Failed to update data:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <CustomModal open={show} onClose={onHide}>
      <CustomForm onSubmit={(e) => { e.preventDefault(); handleUpdate(user.id); }}>
        <CustomModalHeader>
          <CustomModalTitle>Update Profile</CustomModalTitle>
        </CustomModalHeader>
        <Grid className="mt-4" container spacing={2}>
          <Grid item xs={12}>
            <CustomFormControl
              label="User Name"
              variant="outlined"
              name="user_name"
              value={users.user_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormControl
              label="Email"
              variant="outlined"
              type="email"
              name="user_email"
              value={users.user_email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormControl
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              name="user_password"
              value={users.user_password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                    className="text-light"
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormControl
              label="Mobile"
              variant="outlined"
              name="user_mobile"
              value={users.user_mobile}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <CustomModalFooter>
          <CustomSecondaryButton className="me-4" variant="contained" onClick={onHide}>
            Close
          </CustomSecondaryButton>
          <CustomPrimaryButton type="submit" variant="contained">
            Update
          </CustomPrimaryButton>
        </CustomModalFooter>
      </CustomForm>
    </CustomModal>
  );
};

export default ProfileModal;

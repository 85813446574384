import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";

const Appointments = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark", // Switches the palette to dark mode
    },
  });

  const [data, setData] = useState([]);
  const [hospitalId, setHospitalId] = useState(null);
  const { user } = useAuth();
  const userId = user?.user_id;

  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId]);

  const fetchData = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/doctor/getAllToday'sAppointments/${userId}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_id",
        header: "Patient Id",
        size: 80,
      },
      {
        accessorKey: "b_name",
        header: "User Name",
        size: 80,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
      {
        accessorKey: "b_date_of_appointment",
        header: "Date",
        size: 80,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return new Date(value).toLocaleDateString();
        },
      },
      {
        accessorKey: "b_time_of_appointment",
        header: "Time",
        size: 80,
      },
      {
        accessorKey: "b_status",
        header: "Status",
        size: 80,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
      {
        accessorKey: "b_appointment_type",
        header: "Type",
        size: 80,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
    ],
    []
  );

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <MaterialReactTable columns={columns} data={data} />
      </ThemeProvider>
    </div>
  );
};

export default Appointments;

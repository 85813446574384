import React, { useState } from "react";
import "../assets/css/MainPage.css";
import { Icon } from "@mui/material";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ProfileModal from "../modals/ProfileModal";
import LogoutConfirmation from '../components/Logout'; // Updated import

export const NavHeader = ({
  handleToggle,
  isSidebarOpen,
  user,
}) => {
  const history = useHistory();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    // Perform any additional logout logic here if needed
    console.log("Logging out...");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  return (
    <div className="">
      <header className="sidebar-header d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center">
          <button
            type="button"
            onClick={handleToggle}
            className="btn btn-link text-decoration-none text-light"
          >
            <Icon>{isSidebarOpen ? "close" : "menu"}</Icon>
          </button>
        </div>
        <Form inline className="d-flex mx-2">
          <h2 className="titleH text-light">
            <span style={{ color: "red" }}>M</span>
            <span>e</span>
            <span>d</span>
            <span>i</span>
            <span>M</span>
            <span>o</span>
            <span>n</span>
            <span>i</span>
            <span>t</span>
            <span>o</span>
            <span>r</span>
            <span style={{ color: "blue" }}>X</span>
          </h2>
        </Form>
        <div className="headerFont d-flex">
          <button
            onClick={handleProfileClick}
            className="btn btn-link text-decoration-none text-light"
          >
            <Icon>account_circle</Icon>
          </button>
          <ProfileModal
            show={showProfileModal}
            onHide={() => setShowProfileModal(false)}
            user={user}
          />
          <Button
            className="btn text-decoration-none text-light mr-3"
            style={{
              backgroundColor: "transparent",
              border: "none",
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleShowModal}
          >
            <Icon>logout</Icon>
          </Button>
          <LogoutConfirmation
            show={showModal}
            handleClose={handleCloseModal}
            handleLogout={handleLogout}
          />
        </div>
      </header>
    </div>
  );
};

export default NavHeader;

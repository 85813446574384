import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";

const UserDetails = () => {
  const { user } = useAuth();
  const userId = user?.user_id;

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserData(); // Initial fetch

    const fetchDataInterval = setInterval(fetchData, 3000); // Fetch data every 3 seconds

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/receptions`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "r_email",
      header: "Email",
      muiEditTextFieldProps: {
        type: "email",
        required: true,
      },
    },
    {
      accessorKey: "r_role",
      header: "Position",
      muiEditTextFieldProps: {
        required: true,
        defaultValue: "Reception",
      },
    },
    {
      accessorKey: "r_name",
      header: "User Name",
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.row.original.r_name;
      },
    },
    {
      accessorKey: "r_mobile",
      header: "Mobile",
      muiEditTextFieldProps: {
        required: true,
      },
    },
  ]);

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <MaterialReactTable
          columns={columns}
          data={tableData.map((item) => ({
            ...item,
            r_role: item.r_role || "Reception",
          }))}
          // enableFullScreenToggle={false}
        />
      </ThemeProvider>
    </div>
  );
};

export default UserDetails;

import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/css/MedicalReport.css";
import baseURL from "../../config";
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';

function MedicalReportForm() {
  const { user } = useAuth();
  const userId = user?.user_id;
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    hospital_id: "",
    doctor_id: "",
    diseases: "",
    reports: "", // Store the selected PDF file
    user_id: userId,
  });

  const [hospitals, setHospitals] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);

  useEffect(() => {
    fetchHospitalNames();
    fetchDoctorsNames();
  }, []);

  const fetchHospitalNames = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/hospitalNames`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHospitals(data);
    } catch (error) {
      console.error("Error fetching hospital names:", error);
    }
  };

  const fetchDoctorsNames = async () => {
    try {
      const response = await fetch(`${baseURL}/user/doctorNames`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDoctors(data);
    } catch (error) {
      console.log("Error fetching doctor names:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setFormData({ ...formData, reports: e.target.files[0] });
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      // Filter doctors based on selected hospital
      if (name === "hospital_id") {
        const filtered = doctors.filter(doctor => doctor.d_hospital_id === value);
        setFilteredDoctors(filtered);
        // setFormData({ ...formData, doctor_id: "" }); // Reset selected doctor when hospital changes
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("age", formData.age);
      formDataToSend.append("gender", formData.gender);
      formDataToSend.append("hospital_id", formData.hospital_id);
      formDataToSend.append("doctor_id", formData.doctor_id);
      formDataToSend.append("diseases", formData.diseases);
      formDataToSend.append("reports", formData.reports); // Ensure 'reports' matches the key expected by the backend
      formDataToSend.append("user_id", formData.user_id);

      const response = await axios.post(`${baseURL}/user/reports`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Check if response is not OK
      if (response.status !== 200) {
        throw new Error(response.data.message || "Error submitting medical report.");
      }

      // Success case: Clear form data and show success toast
      toast.success("Reports Submitted Successfully!");
      setFormData({
        name: "",
        age: "",
        gender: "",
        hospital_id: "",
        doctor_id: "",
        diseases: "",
        reports: "",
        user_id: userId,
      });
    } catch (error) {
      toast.error(error.message || "Error submitting medical report. Please try again later.");
      console.error(error);
    }
  };

  return (
    <div className="medical-report-form-container">
      <div className="form-sub-div">
        <h2>Medical Report Form</h2>
        <Form onSubmit={handleSubmit} className="medical-report-form">
          <Row>
            <Col md={6}>
              <Form.Group as={Row} controlId="name">
                <Form.Label column md={4}>
                  Patient Name:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="age">
                <Form.Label column md={4}>
                  Age:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="number"
                    name="age"
                    placeholder="Enter Age"
                    value={formData.age}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="gender">
                <Form.Label column md={4}>
                  Gender:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="hospital_name" className="mb-3">
                <Form.Label column md={4}>
                  Select Hospital
                </Form.Label>
                <Col md={8}>
                  <Form.Select
                    name="hospital_id"
                    required
                    value={formData.hospital_id}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>
                      Select Your Hospital
                    </option>
                    {hospitals.map((hospital) => (
                      <option key={hospital.hospital_id} value={hospital.hospital_id}>
                        {hospital.hospital_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <br />
            </Col>
            <Col md={6}>
              <Form.Group as={Row} controlId="doctor_id">
                <Form.Label column md={4}>
                  Doctor ID:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    as="select"
                    name="doctor_id"
                    value={formData.doctor_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Doctor</option>
                    {filteredDoctors.map((doctor) => (
                      <option key={doctor.d_id} value={doctor.d_id}>
                        {doctor.d_name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="diseases">
                <Form.Label column md={4}>
                  Disease:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    as="select"
                    name="diseases"
                    value={formData.diseases}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Disease</option>
                    <option value="flu">Flu</option>
                    <option value="cold">Cold</option>
                    <option value="fever">Fever</option>
                    <option value="headache">Headache</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="reports">
                <Form.Label column md={4}>
                  PDF File:
                </Form.Label>
                <Col md={8}>
                  <Form.Control
                    type="file"
                    name="reports"
                    accept=".pdf"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>
              <br />
            </Col>
          </Row>
          <div className="d-flex justify-content-end p-4">
            <Button type="submit" className="submit-button">
              Submit Report
            </Button>
          </div>
        </Form>
        <ToastContainer className="mt-5" />
      </div>
    </div>
  );
}

export default MedicalReportForm;

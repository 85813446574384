import Dashboard from "../pages/Admin/DashboardPage";
import UserDetails from "../pages/Admin/RegisterUser";
import GetNotification from "../pages/Admin/GetNotification";
import Notification from "../pages/Admin/Notification";
import BillingAndPaymentsPage from "../pages/Admin/BillingAndPaymentsPage ";

const menuItems = [
  {
    name: "Dashboard",
    icon: "dashboard",
    path: <Dashboard />,
  },
  {
    name: "User Accounts Management",
    icon: "account_circle",
    path: <UserDetails />,
  },
  {
    name: "Notifications",
    icon: "settings",
    path: <GetNotification />,
  },
  {
    name: "Send Notifications",
    icon: "settings",
    path: <Notification />,
  },
  {
    name: "Billing and Payments",
    icon: "payments",
    path: <BillingAndPaymentsPage />,
  },
];

export default menuItems;

import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast styles
import Swal from 'sweetalert2';

const UserDetails = () => {
  const { user } = useAuth();
  const userId = user?.user_id;

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [loggedUserHospitalName, setLoggedUserHospitalName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await fetchData();
        await fetchLoggedUserHospitalName();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchUserData(); // Initial fetch
  
    const fetchDataInterval = setInterval(fetchData, 3000); // Fetch data every 3 seconds
    const fetchHospitalNameInterval = setInterval(fetchLoggedUserHospitalName, 60000); // Fetch hospital name every 3 seconds
  
    // Cleanup intervals on component unmount
    return () => {
      clearInterval(fetchDataInterval);
      clearInterval(fetchHospitalNameInterval);
    };
  }, []);
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/admin/user/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLoggedUserHospitalName = async () => {
    try {
      const response = await fetch(`${baseURL}/admin/hospitalNameId/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLoggedUserHospitalName(data.hospital_name);
    } catch (error) {
      console.error("Error fetching logged user's hospital name:", error);
    }
  };

  const handleCreateNewRow = async (values) => {
    try {
      const response = await fetch(`${baseURL}/admin/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          created_id: userId,
          hospital_id: userId,
          d_hospital_id: userId,
          r_hospital_id: userId,
          d_hospital_name: values.d_hospital_name || loggedUserHospitalName,
          r_hospital_name: values.r_hospital_name || loggedUserHospitalName,
        }),
      });
      if (response.ok) {
        toast.success("User created successfully"); // Success toast
        fetchData();
        setCreateModalOpen(false);
      } else {
        toast.error("Error creating user"); // Error toast
        console.error("Error creating user:", response.statusText);
      }
    } catch (error) {
      toast.error("Error creating user"); // Error toast
      console.error("Error creating user:", error);
    }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      const response = await fetch(
        `${baseURL}/admin/users/${row.original.user_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      if (response.ok) {
        fetchData();
        exitEditingMode();
      } else {
        console.error("Error updating user:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  // const handleDeleteRow = async (row) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete ${row.original.user_name}?`
  //     )
  //   ) {
  //     try {
  //       const response = await fetch(
  //         `${baseURL}/admin/users/${row.original.user_id}`,
  //         {
  //           method: "DELETE",
  //         }
  //       );
  //       if (response.ok) {
  //         fetchData();
  //       } else {
  //         console.error("Error deleting user:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting user:", error);
  //     }
  //   }
  // };

  const handleDeleteRow = async (row) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete ${row.original.user_name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${baseURL}/admin/users/${row.original.user_id}`,
          {
            method: 'DELETE',
          }
        );
        if (response.ok) {
          Swal.fire('Deleted!', 'The user has been deleted.', 'success');
          fetchData();
        } else {
          Swal.fire('Error!', 'There was a problem deleting the user.', 'error');
          console.error('Error deleting user:', response.statusText);
        }
      } catch (error) {
        Swal.fire('Error!', 'There was a problem deleting the user.', 'error');
        console.error('Error deleting user:', error);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_email",
        header: "Email",
        muiEditTextFieldProps: {
          type: "email",
          required: true,
          error: !!validationErrors["user_email"],
          helperText: validationErrors["user_email"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_email: "",
            })),
        },
      },
      {
        accessorKey: "user_role",
        header: "Role",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["user_role"],
          helperText: validationErrors["user_role"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_role: "",
            })),
        },
      },
      {
        accessorKey: "user_name",
        header: "User",
        enableEditing: false,
        Cell: ({ cell }) => {
          return cell.row.original.user_name;
        },
      },
      {
        accessorKey: "user_mobile",
        header: "Mobile",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["user_mobile"],
          helperText: validationErrors["user_mobile"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_mobile: "",
            })),
        },
      },
    ],
    [validationErrors]
  );

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <div>
          <MaterialReactTable
            columns={columns}
            data={tableData}
            editingMode="modal"
            enableColumnOrdering
            enableEditing
            // enableFullScreenToggle={false}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={() => setValidationErrors({})}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", gap: "0.1rem" }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(row)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                {isMobile ? "New" : "Create New User"}
              </Button>
            )}
          />
          <CreateNewAccountModal
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            onSubmit={handleCreateNewRow}
            isMobile={isMobile}
            loggedUserHospitalName={loggedUserHospitalName} // Pass the logged user's hospital name to the modal
          />
        </div>
      </ThemeProvider>
      <ToastContainer className="mt-5" /> {/* Add ToastContainer at the root level of UserDetails */} 
    </div>
  );
};

const CreateNewAccountModal = ({
  open,
  onClose,
  onSubmit,
  loggedUserHospitalName,
  isMobile,
}) => {
  const [values, setValues] = useState({
    user_name: "",
    user_email: "",
    user_mobile: "",
    user_password: "",
    user_role: "",
    d_hospital_name: loggedUserHospitalName || "", // Initialize with loggedUserHospitalName if available
    r_hospital_name: loggedUserHospitalName || "", // Initialize with loggedUserHospitalName if available
    d_specialization: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!values.user_name.trim()) {
      errors.user_name = "User Name is required";
      isValid = false;
    }

    if (!values.user_email.trim()) {
      errors.user_email = "Email is required";
      isValid = false;
    } else if (!/\b[A-Z0-9._%+-]+@gmail\.com\b/i.test(values.user_email)) {
      errors.user_email = "Enter a valid Gmail address";
      isValid = false;
    }

    if (!values.user_mobile.trim()) {
      errors.user_mobile = "Phone Number is required";
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(values.user_mobile)) {
      errors.user_mobile = "Invalid Phone Number";
      isValid = false;
    }

    if (!values.user_password.trim()) {
      errors.user_password = "Password is required";
      isValid = false;
    } else if (
      !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(
        values.user_password
      ) ||
      values.user_password.length < 8
    ) {
      errors.user_password =
      "Password at least 8 characters long, it contain at least one upper& lower case, number, and special character ";
      isValid = false;
    }

    if (!values.user_role.trim()) {
      errors.user_role = "Role is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(values);
      resetForm();
      onClose();
    }
  };

  const resetForm = () => {
    setValues({
      user_name: "",
      user_email: "",
      user_mobile: "",
      user_password: "",
      user_role: "",
      hospital_id: "",
      d_hospital_name: loggedUserHospitalName || "",
      r_hospital_name: loggedUserHospitalName || "",
      d_specialization: "",
    });
    setErrors({});
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>Create New User</DialogTitle>
      <DialogContent style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>
        <form className="mt-2" onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="User Name"

                value={values.user_name}
                onChange={(e) =>
                  setValues({ ...values, user_name: e.target.value })
                }
                error={!!errors.user_name}
                helperText={errors.user_name}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                type="email"
                value={values.user_email}
                onChange={(e) =>
                  setValues({ ...values, user_email: e.target.value })
                }
                error={!!errors.user_email}
                helperText={errors.user_email}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Hospital Name"
                value={loggedUserHospitalName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.user_role}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  value={values.user_role}
                  onChange={(e) =>
                    setValues({ ...values, user_role: e.target.value })
                  }
                  required
                >
                  <MenuItem value="doctor">Doctor</MenuItem>
                  <MenuItem value="reception">Reception</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {values.user_role === "doctor" && (
              <Grid item xs={12}>
                <TextField
                  label="Specialization"
                  value={values.d_specialization}
                  onChange={(e) =>
                    setValues({ ...values, d_specialization: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={values.user_mobile}
                onChange={(e) =>
                  setValues({ ...values, user_mobile: e.target.value })
                }
                error={!!errors.user_mobile}
                helperText={errors.user_mobile}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                type="password"
                value={values.user_password}
                onChange={(e) =>
                  setValues({ ...values, user_password: e.target.value })
                }
                error={!!errors.user_password}
                helperText={errors.user_password}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
          disableElevation
        >
          {isMobile ? "Create" : "Create New User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetails;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import '../../assets/css/Toast.css';
import {
  faFaceSadCry,
  faFaceGrinBeam,
} from "@fortawesome/free-solid-svg-icons";
import hero from "../../assets/images/hero.png";
import "../../assets/css/SignupPage.css";
import baseURL from "../../config";

const SignupPage = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_mobile: "",
    user_password: "",
    confirm_password: "",
    user_role: "user",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "user_mobile" && value) {
      if (!/^[6-9]\d{9}$/.test(value)) {
        setMobileError(
          "*Mobile number should start with 6, 7, 8, or 9 and maintain 10 digits."
        );
      } else {
        setMobileError("");
      }
    }

    if (name === "user_email" && value) {
      if (!value.endsWith("@gmail.com")) {
        setEmailError("*Email should have @ and ends with @gmail.com.");
      } else {
        setEmailError("");
      }
    }

    if (name === "user_password" && value) {
      if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
          value
        )
      ) {
        setPasswordError(
          "*Password should have at least 8 characters, one uppercase, one lowercase, one number, and one special character."
        );
      } else {
        setPasswordError("");
      }
    }

    if (name === "confirm_password" && value) {
      if (value === formData.user_password) {
        setConfirmPasswordError("");
      } else {
        setConfirmPasswordError("*Passwords do not match.");
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if passwords match
    if (formData.user_password !== formData.confirm_password) {
      setConfirmPasswordError("*Passwords do not match.");
      return; // Prevent further execution
    }
  
    try {
      const response = await axios.post(`${baseURL}/api/register`, formData);
  
      if (response.status === 200) {
        toast.success("User Successfully Registered");
  
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Error during registration:", error);
  
      toast.error("Invalid credentials or User Already Existing!");
  
      if (error.response) {
        const errorMessage = error.response.data;
        if (errorMessage && errorMessage.includes("Passwords do not match")) {
          setConfirmPasswordError("*Passwords do not match.");
        }
      }
    }
  };
   

  return (
    <div className="signup-page">
      <ToastContainer className="toast-position" position="top-left"/>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={6} className="user-animation">
            <div className="user-icon">
              <img className="hero-animated img-fluid " src={hero} alt="hero" />
            </div>
          </Col>
          <Col md={6}>
            <div className="tech2">
              <h1>
                <span>Medi</span>Monitor<span>X</span>
              </h1>
            </div>
            <div className="signup-form">
              <h2 className="text-center mb-4">Sign Up</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="user_name">
                  <Form.Control
                    type="text"
                    name="user_name"
                    placeholder="Enter Full Name"
                    value={formData.user_name}
                    onChange={handleChange}
                    required
                    style={{ marginBottom: "15px" }}
                  />
                </Form.Group>
                <Form.Group controlId="user_email">
                  <Form.Control
                    type="email"
                    name="user_email"
                    placeholder="Enter Email"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                    style={{
                      marginBottom: "15px",
                      borderColor: emailError ? "#dc3545" : "",
                    }}
                  />
                  {emailError && <p className="error-message">{emailError}</p>}
                </Form.Group>
                <Form.Group controlId="user_mobile">
                  <Form.Control
                    type="text"
                    name="user_mobile"
                    placeholder="Enter Phone Number"
                    value={formData.user_mobile}
                    onChange={handleChange}
                    required
                    style={{
                      marginBottom: "15px",
                      borderColor: mobileError ? "#dc3545" : "",
                    }}
                  />
                  {mobileError && <p className="error-message">{mobileError}</p>}
                </Form.Group>
                <Form.Group controlId="user_password">
                  <div className="password-input">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="user_password"
                      value={formData.user_password}
                      onChange={handleChange}
                      required
                      style={{
                        marginBottom: "15px",
                        borderColor: passwordError ? "#dc3545" : "",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faFaceSadCry : faFaceGrinBeam}
                      onClick={togglePasswordVisibility}
                      className="password-icon"
                    />
                  </div>
                  {passwordError && (
                    <p className="error-message">{passwordError}</p>
                  )}
                </Form.Group>
                <Form.Group controlId="confirm_password">
                  <div className="password-input">
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      required
                      style={{
                        marginBottom: "15px",
                        borderColor: confirmPasswordError ? "#dc3545" : "",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faFaceSadCry : faFaceGrinBeam}
                      onClick={toggleConfirmPasswordVisibility}
                      className="password-icon"
                    />
                  </div>
                  {confirmPasswordError && (
                    <p className="error-message">{confirmPasswordError}</p>
                  )}
                </Form.Group>
                <div className="d-flex">
                  <Button variant="primary" className="m-2" type="submit" block>
                    Sign Up
                  </Button>
                  <div className="text-center mt-3 ">
                    <p>
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignupPage;

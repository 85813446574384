import React, { useState, useEffect } from "react";
import "../../assets/css/MainPage.css";
import { useLocation } from "react-router-dom";
import NavButton from "../../components/NavButton";
import NavHeader from "../../components/NavHeader";
import AdminItems from "../../utils/AdminMenuItems";
import { useMediaQuery } from "@react-hook/media-query";

const AdminDashboard = () => {
  const [activeItem, setActiveItem] = useState(AdminItems[0]?.name);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const location = useLocation();
  const user = location.state?.user; // Access user data passed via route state

  // Use useMediaQuery hook to detect mobile devices
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Effect hook to close sidebar when mobile device is detected
  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  const handleClick = (item, isMainNav) => {
    console.log("Handle click:", item);
    setActiveItem(item);
    if (isMobile) {
      setIsSidebarOpen(false); // Close sidebar when clicking any menu button on mobile
    }
  };

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("Toggling sidebar", isSidebarOpen ? "Closed" : "Opened");
  };

  return (
    
      <div className="container-fluid nav-padding-handling">
     
     <div>
     <NavHeader
        user={user}
        fixed="top"
        handleToggle={handleToggle}
        isSidebarOpen={isSidebarOpen}
      />
     </div>
    
    
     <div className="row g-0">
       <div className={`col-12 padding-adjust-sideBar ${isSidebarOpen ? "col-md-2" : "d-none"}`}>
         <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
           {AdminItems.map((item) => (
             <div key={item.name}>
               <NavButton
                 onClick={() => handleClick(item.name, !item.items)}
                 name={item.name}
                 icon={item.icon}
                 isActive={activeItem === item.name}
               />
             </div>
           ))}
         </aside>
       </div>
       <div className={`col-12 padding-adjust-main-content ${isSidebarOpen ? "col-md-10" : "col-md-12"}`}>
         <div className="content">
           <div className="main-content">
             <div className="content-wrapper">
               {AdminItems.map(
                 (item) =>
                   activeItem === item.name && (
                     <div key={item.path}>
                       <div className="pt-2">{item.path}</div>
                     </div>
                   )
               )}
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  
  );
};

export default AdminDashboard;

import React, { useState, useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  IconButton,
  Modal,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialReactTable } from "material-react-table"; 
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";

const AppointmentList = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  const [appointments, setAppointments] = useState([]);
  const { user } = useAuth();
  const userId = user?.user_id;
  const [hospitalId, setHospitalId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({
    appointment_id: "",
    b_name: "",
    consulted_Date: "",
    consulted_Time: "",
    b_status: "consulted",
    reschedule_date: "",
    reschedule_time: "",
    cancel_date: "",
    cancel_time: "",
  });

  useEffect(() => {
    if (userId) {
      fetchHospitalId(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (hospitalId) {
      fetchAppointments(hospitalId);
    } 
    const now = new Date();
    const formattedDate = now.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    const formattedTime = now.toTimeString().split(' ')[0]; // Format time as HH:MM:SS

    setCurrentDate(formattedDate);
    setCurrentTime(formattedTime);

  }, [hospitalId]);

  const fetchHospitalId = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/user/getHospitalId/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch hospital ID");
      }
      const data = await response.json();
      setHospitalId(data.hospital_id);
      console.log("Fetched hospital ID:", data.hospital_id);
    } catch (error) {
      console.error("Error fetching hospital ID:", error);
    }
  };

  const fetchAppointments = async (hospitalId) => {
    try {
      const response = await fetch(`${baseURL}/user/getPendingAppointments/${hospitalId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const formattedData = data.map((appointment) => ({
        ...appointment,
        b_date_of_appointment: new Date(appointment.b_date_of_appointment).toLocaleDateString('en-GB'), //Formating the date to day/month/year.
        reschedule_date: new Date(appointment.reschedule_date).toLocaleDateString('en-GB'),
      }));
      setAppointments(formattedData);
      console.log("Fetched appointments:", formattedData);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const handleOpen = (appointment) => {
    setSelectedAppointment({
      appointment_id: appointment.appointment_id,
      b_name: appointment.b_name,
      consulted_Date: "",
      consulted_Time:"",
      b_status: "consulted",
      reschedule_date: "",
      reschedule_time: "",
      cancel_date: "",
      cancel_time: "",
    });
    setOpen(true);
  };
  

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAppointment((prev) => ({ ...prev, [name]: value }));
  };

  const handleStatusUpdate = async () => {
    let appointmentUpdate = { ...selectedAppointment };

    if (appointmentUpdate.b_status === "reschedule") {
      delete appointmentUpdate.cancel_date;
      delete appointmentUpdate.cancel_time;
      // Ensure reschedule date and time are set
      appointmentUpdate.reschedule_date = selectedAppointment.reschedule_date;
      appointmentUpdate.reschedule_time = selectedAppointment.reschedule_time;
    } else if (appointmentUpdate.b_status === "cancel") {
      appointmentUpdate.cancel_date = currentDate;
      appointmentUpdate.cancel_time = currentTime;
      delete appointmentUpdate.reschedule_date;
      delete appointmentUpdate.reschedule_time;
    } else if (appointmentUpdate.b_status === "consulted") {
      appointmentUpdate.consulted_Date = currentDate;
      appointmentUpdate.consulted_Time = currentTime;
      delete appointmentUpdate.reschedule_date;
      delete appointmentUpdate.reschedule_time;
      delete appointmentUpdate.cancel_date;
      delete appointmentUpdate.cancel_time;
    }

    try {
      const response = await fetch(
        `${baseURL}/user/updateAppointmentStatus/${appointmentUpdate.appointment_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(appointmentUpdate),
        }
      );
      toast.success("Updated Successfully ");
      if (!response.ok) {
        toast.error("Update Failed!!");
        throw new Error("Failed to update appointment");
      }
      const data = await response.json();
      console.log("Appointment updated:", data);
      if (hospitalId) {
        fetchAppointments(hospitalId);
      }
      handleClose();
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  const columns = [
    {
      accessorKey: "update_status",
      header: "Edit",
      size: 80,
      Cell: ({ row }) => (
        <IconButton style={{ color: "white" }} onClick={() => handleOpen(row.original)}>
          <EditIcon />
        </IconButton>
      ),
    },
    { accessorKey: "created_id", header: "Patient Id", size: 80 },
    { accessorKey: "b_name", header: "Name", size: 80, 
      Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
    { accessorKey: "b_date_of_appointment", header: "Date", size: 80 },
    { accessorKey: "b_time_of_appointment", header: "Time", size: 80 },
    { accessorKey: "b_appointment_type", header: "Type", size: 80, 
      Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
    { accessorKey: "b_status", header: "Status", size: 80, 
      Cell: ({ cell }) => {
      const value = cell.getValue();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } },
    { accessorKey: "reschedule_date", header: "Reschedule Date", size: 80 },
    { accessorKey: "reschedule_time", header: "Reschedule Time", size: 80 },
   
  ];

  return (
    <div className="TableC">
       <ToastContainer className="mt-5"/> {/* ToastContainer for displaying notifications */}
      <ThemeProvider theme={darkTheme}>
        <MaterialReactTable columns={columns} data={appointments} />
      </ThemeProvider>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, bgcolor: "#1d212a", color: "white", width: 400 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h2>Edit Appointment</h2>
            <IconButton onClick={handleClose} style={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Patient Name"
            name="b_name"
            value={selectedAppointment.b_name}
            onChange={handleInputChange}
            margin="normal"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" }, readOnly: true }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel style={{ color: "white" }}>Status</InputLabel>
            <Select
              name="b_status"
              value={selectedAppointment.b_status}
              onChange={handleInputChange}
              style={{ color: "white" }}
            >
              <MenuItem value="consulted">Consulted</MenuItem>
              <MenuItem value="cancel">Cancelled</MenuItem>
              <MenuItem value="reschedule">Reschedule</MenuItem>
            </Select>
          </FormControl>
          {selectedAppointment.b_status === "reschedule" ? (
            <>
              <TextField
                fullWidth
                label="Reschedule Date"
                type="date"
                name="reschedule_date"
                value={selectedAppointment.reschedule_date}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
              />
              <TextField
                fullWidth
                label="Reschedule Time"
                type="time"
                name="reschedule_time"
                value={selectedAppointment.reschedule_time}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
              />
            </>
          ) : selectedAppointment.b_status === "cancel" ? (
            <>
              <TextField
                fullWidth
                label="Cancel Date"
                type="date"
                name="cancel_date"
                value={currentDate}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" }, readOnly: true }}
              />
              <TextField
                fullWidth
                label="Cancel Time"
                type="time"
                name="cancel_time"
                value={currentTime}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" }, readOnly: true }}
              />
            </>
          ) : (
            <>
              <TextField
                fullWidth
                label="Consulted Date"
                type="date"
                name="consulted_Date"
                value={currentDate}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
              />
              <TextField
                fullWidth
                label="Consulted Time"
                type="time"
                name="consulted_Time"
                value={currentTime}
                onChange={handleInputChange}
                margin="normal"
                InputLabelProps={{ shrink: true, style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
              />
            </>
          )}
          <Button variant="contained" color="primary" onClick={handleStatusUpdate} fullWidth>
            Update
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AppointmentList;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

import Dashboard from "../pages/User/Dashboard";
import MedicalRecord from "../pages/User/SendingMedicalReport";
import History from "../pages/User/History";
import Support from "../pages/User/Support";
import Appointments from "../pages/User/BookAppointment";

const menuItems = [
  {
    name: "Dashboard",
    icon: "dashboard",
    path: <Dashboard />,
  },
  {
    name: "Book Appointment",
    icon: "book_online",
    path: <Appointments />,
  },
  {
    name: "Medical Report",
    icon: "playlist_add_check_circle_outlined",
    path: <MedicalRecord />,
  },
  {
    name: "Appointment History",
    icon: "book_online",
    path: <History />,
  },
  {
    name: "Support",
    icon: "forum_outlined",
    path: <Support />,
  },

];

export default menuItems;

import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast styles
import Swal from 'sweetalert2';


const UserDetails = () => {
  const { user } = useAuth();
  const userId = user?.user_id;

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hospitalNames, setHospitalNames] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    fetchData();
    fetchHospitalNames();
    const interval = setInterval(fetchData, 3000); // Fetch data every 1 minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/user/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchHospitalNames = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/hospitalNames`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHospitalNames(data);
    } catch (error) {
      console.error("Error fetching hospital names:", error);
    }
  };

  const handleCreateNewRow = async (values) => {
    try {
      // Fetch the hospital name based on the selected hospital ID
      const selectedHospital = hospitalNames.find(
        (hospital) => hospital.hospital_id === values.hospital_id
      );
  
      if (!selectedHospital) {
        console.error("Error: Selected hospital not found");
        return;
      }
  
      const body = {
        ...values,
        created_id: userId,
        d_hospital_name: selectedHospital.hospital_name, // Include the hospital name
        r_hospital_name: selectedHospital.hospital_name,
      };
  
      const response = await fetch(`${baseURL}/superUser/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        toast.success("User created successfully"); // Success toast
        fetchData();
        setCreateModalOpen(false);
      } else {
        toast.error("Error creating User"); // Error toast
        console.error("Error creating user:", response.statusText);
      }
    } catch (error) {
      toast.error("Error creating User"); // Error toast
      console.error("Error creating user:", error);
    }
  };
  
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      const response = await fetch(
        `${baseURL}/superUser/users/${row.original.user_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      if (response.ok) {
        fetchData();
        exitEditingMode();
      } else {
        console.error("Error updating user:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  // const handleDeleteRow = async (row) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete ${row.original.user_name}?`
  //     )
  //   ) {
  //     try {
  //       const response = await fetch(
  //         `${baseURL}/superUser/users/${row.original.user_id}`,
  //         {
  //           method: "DELETE",
  //         }
  //       );
  //       if (response.ok) {
  //         fetchData();
  //       } else {
  //         console.error("Error deleting user:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting user:", error);
  //     }
  //   }
  // };

  const handleDeleteRow = async (row) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete ${row.original.user_name}?`,
      icon: 'warning',
      background:'primary',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${baseURL}/superUser/users/${row.original.user_id}`,
          {
            method: 'DELETE',
          }
        );
        if (response.ok) {
          Swal.fire('Deleted!', 'The user has been deleted.', 'success');
          fetchData();
        } else {
          Swal.fire('Error!', 'There was a problem deleting the user.', 'error');
          console.error('Error deleting user:', response.statusText);
        }
      } catch (error) {
        Swal.fire('Error!', 'There was a problem deleting the user.', 'error');
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleValidation = (value, field) => {
    let errors = {};
  
    if (field === 'user_mobile') {
      const mobileRegex = /^[6-9]\d{9}$/;
      if (!mobileRegex.test(value)) {
        errors.user_mobile = 'Invalid mobile number. Must be 10 digits and start with 6, 7, 8, or 9.';
      }
    }
  
    return errors;
  };
  
  const handleSaveRow = (values) => {
    const errors = handleValidation(values.user_mobile, 'user_mobile');
    setValidationErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      // Proceed with save logic
      // For example, you can make an API call here to save the updated row
    } else {
      // Prevent update if there are validation errors
      console.error('Validation errors:', errors);
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "user_email",
        header: "Email",
        muiEditTextFieldProps: {
          type: "email",
          required: true,
          error: !!validationErrors["user_email"],
          helperText: validationErrors["user_email"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_email: "",
            })),
        },
      },
      {
        accessorKey: "user_role",
        header: "Role",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["user_role"],
          helperText: validationErrors["user_role"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_role: "",
            })),
        },
      },
      {
        accessorKey: "user_name",
        header: "User",
        enableEditing: false,
        Cell: ({ cell }) => {
          return cell.row.original.user_name;
        },
      },
      {
        accessorKey: "hospital_name",
        header: "Hospital Name",
        enableEditing: false, // Hospital name should not be editable directly
        Cell: ({ cell }) => {
          const hospital = hospitalNames.find(
            (hospital) => hospital.hospital_id === cell.row.original.hospital_id
          );
          const userRole = cell.row.original.user_role;
          return userRole === "doctor" || userRole === "reception"
            ? hospital
              ? hospital.hospital_name
              : ""
            : cell.row.original.user_name;
        },
      },
      // {
      //   accessorKey: "user_mobile",
      //   header: "Mobile",
      //   muiEditTextFieldProps: {
      //     required: true,
      //     error: !!validationErrors["user_mobile"],
      //     helperText: validationErrors["user_mobile"],
      //     onFocus: () =>
      //       setValidationErrors((prevErrors) => ({
      //         ...prevErrors,
      //         user_mobile: "",
      //       })),
      //   },
      // },

     
      
      // Table column definition
      {
        accessorKey: "user_mobile",
        header: "Mobile",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["user_mobile"],
          helperText: validationErrors["user_mobile"],
          onBlur: (event) => {
            const { name, value } = event.target;
            const errors = handleValidation(value, name);
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              ...errors,
            }));
          },
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              user_mobile: "",
            })),
        },
      }
    ],
    [validationErrors, hospitalNames]
  );

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <div >
          <MaterialReactTable
         
            columns={columns}
            data={tableData}
            editingMode="modal"
            enableColumnOrdering
            enableEditing
            // enableFullScreenToggle={false}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={() => setValidationErrors({})}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", gap: "0.1rem"}}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(row)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                {isMobile ? "New" : "Create New User"}
              </Button>
            )}
          />
          <CreateNewAccountModal
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            onSubmit={handleCreateNewRow}
            hospitals={hospitalNames}
            isMobile={isMobile}
          />
        </div>
      </ThemeProvider>
      <ToastContainer className="mt-5" /> {/* Add ToastContainer at the root level of UserDetails */} 
    </div>
  );
};

const CreateNewAccountModal = ({
  
  open,
  onClose,
  onSubmit,
  hospitals,
  isMobile,
}) => {
  const [values, setValues] = useState({
    user_name: "",
    user_email: "",
    user_mobile: "",
    user_password: "",
    user_role: "",
    hospital_id: "",
    d_hospital_name: "",
    r_hospital_name: "",
    d_specialization: "", // Added specialization field
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!values.user_name.trim()) {
      errors.user_name = "User Name is required";
      isValid = false;
    }

    if (!values.user_email.trim()) {
      errors.user_email = "Email is required";
      isValid = false;
    } else if (!/\b[A-Z0-9._%+-]+@gmail\.com\b/i.test(values.user_email)) {
      errors.user_email = "Enter a valid Gmail address";
      isValid = false;
    }

    if (!values.hospital_id) {
      errors.hospital_id = "Hospital selection is required";
      isValid = false;
    }

    if (!values.user_role) {
      errors.user_role = "Role selection is required";
      isValid = false;
    }

    if (values.user_role === "doctor" && !values.d_specialization.trim()) {
      errors.d_specialization = "Specialization is required for doctors";
      isValid = false;
    }

    if (!values.user_mobile.trim()) {
      errors.user_mobile = "Phone Number is required";
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(values.user_mobile)) {
      errors.user_mobile = "Invalid Phone Number";
      isValid = false;
    }

    if (!values.user_password.trim()) {
      errors.user_password = "Password is required";
      isValid = false;
    } else if (
      !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(values.user_password) ||
      values.user_password.length < 8
    ) {
      errors.user_password =
      "Password at least 8 characters,it contain at least one upper& lowercase, number, and special character ";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(values);
      setValues({
        user_name: "",
        user_email: "",
        user_mobile: "",
        user_password: "",
        user_role: "",
        hospital_id: "",
        d_hospital_name: "",
        r_hospital_name: "",
        d_specialization: "", // Reset specialization after submission
      });
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>Create New User</DialogTitle>
      <DialogContent style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>
        <form className="mt-4" onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid  item xs={12} sm={6}>
              <TextField
                label="User Name"
                value={values.user_name}
                onChange={(e) =>
                  setValues({ ...values, user_name: e.target.value })
                }
                error={!!errors.user_name}
                helperText={errors.user_name}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                type="email"
                value={values.user_email}
                onChange={(e) =>
                  setValues({ ...values, user_email: e.target.value })
                }
                error={!!errors.user_email}
                helperText={errors.user_email}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.hospital_id}>
                <InputLabel id="hospital-label">Hospital</InputLabel>
                <Select
                  labelId="hospital-label"
                  label="Hospital"
                  value={values.hospital_id}
                  onChange={(e) => {
                    const selectedHospital = hospitals.find(
                      (hospital) => hospital.hospital_id === e.target.value
                    );
                    setValues({
                      ...values,
                      hospital_id: e.target.value,
                      d_hospital_name: selectedHospital
                        ? selectedHospital.hospital_name
                        : "",
                    });
                  }}
                >
                  {hospitals.map((hospital) => (
                    <MenuItem
                      key={hospital.hospital_id}
                      value={hospital.hospital_id}
                    >
                      {hospital.hospital_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.hospital_id && (
                  <FormHelperText>{errors.hospital_id}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.user_role}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  label="Role"
                  value={values.user_role}
                  onChange={(e) =>
                    setValues({ ...values, user_role: e.target.value })
                  }
                >
                  <MenuItem value="">Select Role</MenuItem>
                  <MenuItem value="doctor">Doctor</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="reception">Reception</MenuItem>
                </Select>
                {errors.user_role && (
                  <FormHelperText>{errors.user_role}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {values.user_role === "doctor" && (
              <Grid item xs={12}>
                <TextField
                  label="Specialization"
                  value={values.d_specialization}
                  onChange={(e) =>
                    setValues({ ...values, d_specialization: e.target.value })
                  }
                  error={!!errors.d_specialization}
                  helperText={errors.d_specialization}
                  required={values.user_role === "doctor"}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={values.user_mobile}
                onChange={(e) =>
                  setValues({ ...values, user_mobile: e.target.value })
                }
                error={!!errors.user_mobile}
                helperText={errors.user_mobile}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                // type="password"
                type={showPassword ? "text" : "password"} 
                value={values.user_password}
                onChange={(e) =>
                  setValues({ ...values, user_password: e.target.value })
                }
                error={!!errors.user_password}
                helperText={errors.user_password}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }} style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
        >
          {isMobile ? "Create" : "Create New User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetails;

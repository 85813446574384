import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";

const UserDetails = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserData(); // Initial fetch

    const fetchDataInterval = setInterval(fetchData, 3000); // Fetch data every 3 seconds
    // Cleanup intervals on component unmount
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/patients`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_id",
        header: "ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "p_name",
        header: "Name",
      },
      // {
      //   accessorKey: "p_mobile",
      //   header: "Mobile",
      // },
      {
        accessorKey: "p_disease",
        header: "Disease",
      },
      {
        accessorKey: "d_hospital_id",
        header: "Hospital",
      },
            {
        accessorKey: "p_d_id",
        header: "Doctor ID",
      },
    ],
    []
  );
  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <div>
          <MaterialReactTable
            columns={columns}
            data={tableData}
            // enableFullScreenToggle={false}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default UserDetails;

import React, { useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import "../../assets/css/Notification.css";
import baseURL from "../../config";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import css for react-toastify

const Notify = () => {
  const [message, setMessage] = useState("");
  const { user } = useAuth(); // Use useAuth to get logged-in user
  const userId = user?.user_id;

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendNotification = () => {
    const created_by = userId; // Replace with actual user information
    axios
      .post(`${baseURL}/admin/notifications`, { message, created_by })
      .then((res) => {
        // alert("Notification sent successfully!");
        toast.success("Notification sent successfully!"); // Notify success
        setMessage("");
      })
      .catch((err) => {
        console.error(err);
        // alert("Failed to send notification");
        toast.error("Failed to send notification"); // Notify error
      });
  };

  return (
    <Container>
      <ToastContainer className="mt-5" />{" "}
      {/* ToastContainer for displaying notifications */}
      <Card className="bg-dark text-light" style={{backgroundColor:"#1d212a", color:"#B2BEB5"}}>
        {/* <Card.Header>Notification Panel</Card.Header> */}
        <Card.Body style={{backgroundColor:"#1d212a", color:"#B2BEB5"}}>
          <Card.Title className="titleC">
            This Notification Managed by Higher Authorities
          </Card.Title>
          <Card.Text>
            <div>
              <textarea
                className="message-input justify-center"
                type="text"
                rows="6"
                cols="70"
                wrap="soft"
                value={message}
                onChange={handleInputChange}
                placeholder="Enter message"
              />
            </div>
          </Card.Text>
          <Button
            variant="info"
            className="mt-2 send-notification-button"
            onClick={handleSendNotification}
          >
            Send
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Notify;

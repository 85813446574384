import Dashboard from "../pages/SuperAdmin/DashboardPage.js";
import RegisterHospital from "../pages/SuperAdmin/RegisterHospital.js";
import UserDetails from "../pages/SuperAdmin/RegisterUser.js";
import Notification from "../pages/SuperAdmin/Notification.js";

const menuItems = [
  {
    name: "Dashboard",
    icon: "dashboard",
    path: <Dashboard />,
  },
  {
    name: "Hospital Management",
    icon: "local_hospital",
    path: <RegisterHospital />,
  },
  {
    name: "User Accounts Management",
    icon: "account_circle",
    path: <UserDetails />,
  },
  {
    name: "Notifications",
    icon: "settings",
    path: <Notification />,
  },
];

export default menuItems;

import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";
import { format, parseISO } from 'date-fns';


const History = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [appointments, setAppointments] = useState([]);
  const { user } = useAuth();
  const userId = user?.user_id;
 

  useEffect(() => {
    if (userId) {
      fetchAppointments(userId);
    }
  }, [userId]);

  const fetchAppointments = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/user/userAppointments/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Raw data received from API:", data);

      // Function to format date to DD-MM-YYYY
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = parseISO(dateString);
        return format(date, 'dd-MM-yyyy');
      };

      // Ensure data arrays are defined and combine all appointment types
      const consulted = Array.isArray(data.consulted) ? data.consulted : [];
      const reschedule = Array.isArray(data.reschedule) ? data.reschedule : [];
      const cancel = Array.isArray(data.cancel) ? data.cancel : [];
      const pending = Array.isArray(data.pending) ? data.pending : [];

      // Combine all appointment types into a single array
      const combinedData = [
        ...consulted.map(appointment => ({
          ...appointment,
          type: "Consulted",
          consulted_Date: formatDate(appointment.consulted_Date),
        })),
        ...reschedule.map(appointment => ({
          ...appointment,
          type: "Reschedule",
          reschedule_date: formatDate(appointment.reschedule_date),
        })),
        ...cancel.map(appointment => ({
          ...appointment,
          type: "Cancel",
          cancel_date: formatDate(appointment.cancel_date),
        })),
          ...pending.map(appointment => ({
          ...appointment,
          // type: "Pending",
          // b_status: formatDate(appointment.book),
        })),
      ];

      // Format the b_date_of_appointment for all appointments
      const formattedAppointments = combinedData.map(appointment => ({
        ...appointment,
        b_date_of_appointment: formatDate(appointment.b_date_of_appointment),
      }));

      setAppointments(formattedAppointments);
      console.log("Fetched appointments:", formattedAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const columns = [
    { accessorKey: "created_id", header: "Patient Id", size: 80,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value.charAt(0).toUpperCase() + value.slice(1);
      }},
    { accessorKey: "b_name", header: "Name", size: 80,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value.charAt(0).toUpperCase() + value.slice(1);
      }},
    { accessorKey: "b_date_of_appointment", header: "Booking Date", size: 80 },
    { accessorKey: "b_time_of_appointment", header: "Booking Time", size: 80 },
    { accessorKey: "b_appointment_type", header: "Type", size: 80,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value.charAt(0).toUpperCase() + value.slice(1);
      }},
    { accessorKey: "b_status", header: "Status", size: 80,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value.charAt(0).toUpperCase() + value.slice(1);
      }},
    { accessorKey: "consulted_Date", header: "Consulted Date", size: 80 },
    { accessorKey: "consulted_Time", header: "Consulted Time", size: 80 },
    { accessorKey: "reschedule_date", header: "Reschedule Date", size: 80 },
    { accessorKey: "reschedule_time", header: "Reschedule Time", size: 80 },
    { accessorKey: "cancel_date", header: "Cancel Date", size: 80 },
    { accessorKey: "cancel_time", header: "Cancel Time", size: 80 },
  ];

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        
        <MaterialReactTable columns={columns} data={appointments} />
        
      </ThemeProvider>
    </div>
  );
};

export default History;

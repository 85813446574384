import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";

const PatientList = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark", // Switches the palette to dark mode
    },
  });

  const [data, setData] = useState([]);
  const [hospitalId, setHospitalId] = useState(null);
  const { user } = useAuth();
  const userId = user?.user_id;

  
  useEffect(() => {
    fetchData(userId);
  }, []);


  // const fetchHospitalId = async (userId) => {
  //   try {
  //     const response = await fetch(`${baseURL}/user/getHospitalId/${userId}`);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch hospital ID");
  //     }
  //     const data = await response.json();
  //     setHospitalId(data.hospital_id);
  //     console.log("Fetched hospital ID:", data.hospital_id);
  //   } catch (error) {
  //     console.error("Error fetching hospital ID:", error);
  //   }
  // };

  const fetchData = async (userId) => {
    try {
      const response = await fetch(`${baseURL}/doctor/patients/${userId}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const columns = [
    {
      accessorKey: "user_id",
      header: "Patient ID",
      enableEditing: false,
      size: 80,
    },
    { accessorKey: "p_name", header: "Patient Name", size: 80 },
    { accessorKey: "p_age", header: "Age", size: 80 },
    { accessorKey: "p_gender", header: "Gender", size: 80 },
    { accessorKey: "p_disease", header: "Diseases", size: 80 },
  ];

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <MaterialReactTable columns={columns} data={data} />
      </ThemeProvider>
    </div>
  );
};

export default PatientList;

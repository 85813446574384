import Appointments from "../pages/Reception/AppointmentManagementPage.js";
// import BillingAndPaymentsPage from "../pages/Reception/BillingAndPaymentsPage .js";
import NotificationsPage from "../pages/Reception/NotificationsPage .js";
import PatientCheckInPage from "../pages/Reception/PatientCheckInPage.js";
import PatientCheckOutPage from "../pages/Reception/PatientCheckOut.js";
import PatientRecordsPage from "../pages/Reception/PatientRecordsPage.js";



const ReceptionItems = [
  {
    name: "Appointment Management",
    icon: "book_online",
    path: <Appointments />,
  },
  {
    name: "Need To CheckIns",
    icon: "check_in_out",
    path: <PatientCheckInPage />,
  },
  {
    name: "Check Outs",
    icon: "check_out",
    path: <PatientCheckOutPage />,
  },
  {
    name: "Patient Records",
    icon: "playlist_add_check_circle",
    path: <PatientRecordsPage />,
  },
  // {
  //   name: "Billing and Payments",
  //   icon: "payments",
  //   path: <BillingAndPaymentsPage />,
  // },
  {
    name: "Notifications",
    icon: "notification_important",
    path: <NotificationsPage />,
  },
];

export default ReceptionItems;

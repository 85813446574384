import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext";

const UserDetails = () => {
  const { user } = useAuth();
  const userId = user?.user_id;

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserData(); // Initial fetch

    const fetchDataInterval = setInterval(fetchData, 3000); // Fetch data every 3 seconds

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/admin/patients/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "user_id",
      header: "Patient Id",
      muiEditTextFieldProps: {
        required: true,
      },
    },
    {
      accessorKey: "p_name",
      header: "User Name",
      muiEditTextFieldProps: {
        required: true,
      },
    },
    // {
    //   accessorKey: "p_mobile",
    //   header: "Mobile",
    //   enableEditing: false,
    //   Cell: ({ cell }) => {
    //     return cell.row.original.p_name;
    //   },
    // },
    {
        accessorKey: "p_age",
        header: "Age",
        muiEditTextFieldProps: {
          required: true,
        },
      },
    {
      accessorKey: "p_disease",
      header: "Diseases",
      muiEditTextFieldProps: {
        required: true,
      },
    },
  ]);

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <div>
          <MaterialReactTable
            columns={columns}
            data={tableData}
            // enableFullScreenToggle={false}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default UserDetails;

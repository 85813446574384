
import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import baseURL from "../../config";
import "../../assets/css/Tables.css";
import { useAuth } from "../../context/AuthContext"; // Import useAuth
import { ToastContainer, toast } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast styles
import Swal from 'sweetalert2'; 

const Hospital = () => {
  const { user } = useAuth(); // Use useAuth to get logged-in user
  const userId = user?.user_id; // Get the user ID
  const isMobile = useMediaQuery("(max-width:600px)");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
 

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // Fetch data every 1 minute (60000 ms)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}/superUser/hospitals`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCreateNewRow = async (values) => {
    try {
      const body = {
        ...values,
        created_id: userId, // Add created_id here
      };

      const response = await fetch(`${baseURL}/superUser/hospitals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        toast.success("Hospital created successfully"); // Success toast
        fetchData();
        setCreateModalOpen(false);
      } else {
        toast.error("Error creating Hospital"); // Error toast
        console.error("Error creating hospital:", response.statusText);
      }
    } catch (error) {
      toast.error("Error creating Hospital"); // Error toast
      console.error("Error creating hospital:", error);
    }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      const response = await fetch(
        `${baseURL}/superUser/hospitals/${row.original.hospital_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      if (response.ok) {
        fetchData();
        exitEditingMode();
      } else {
        console.error("Error updating hospital:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating hospital:", error);
    }
  };

  // const handleDeleteRow = async (row) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete ${row.original.hospital_name}?`
  //     )
  //   ) {
  //     try {
  //       const response = await fetch(
  //         `${baseURL}/superUser/hospitals/${row.original.hospital_id}`,
  //         {
  //           method: "DELETE",
  //         }
  //       );
  //       if (response.ok) {
  //         fetchData();
  //       } else {
  //         console.error("Error deleting hospital:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting hospital:", error);
  //     }
  //   }
  // };

  const handleDeleteRow = async (row) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete ${row.original.hospital_name}?`,
      icon: 'warning',
      showCancelButton: true,
      backgroundColor:'#1d212a',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${baseURL}/superUser/hospitals/${row.original.hospital_id}`,
          {
            method: 'DELETE',
          }
        );
        if (response.ok) {
          Swal.fire('Deleted!', 'The hospital has been deleted.', 'success');
          fetchData();
        } else {
          Swal.fire('Error!', 'There was a problem deleting the hospital.', 'error');
          console.error('Error deleting hospital:', response.statusText);
        }
      } catch (error) {
        Swal.fire('Error!', 'There was a problem deleting the hospital.', 'error');
        console.error('Error deleting hospital:', error);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "hospital_id",
        header: "Hospital ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "hospital_name",
        header: "Hospital Name",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["hospital_name"],
          helperText: validationErrors["hospital_name"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({ ...prevErrors, name: "" })),
        },
      },
      {
        accessorKey: "hospital_address",
        header: "Address",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["hospital_address"],
          helperText: validationErrors["hospital_address"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              address: "",
            })),
        },
      },
      {
        accessorKey: "hospital_mobile",
        header: "Phone Number",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors["hospital_mobile"],
          helperText: validationErrors["hospital_mobile"],
          onFocus: () =>
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              phone_number: "",
            })),
        },
      },
    ],
    [validationErrors]
  );

  return (
    <div className="TableC">
      <ThemeProvider theme={darkTheme}>
        <div>
          <MaterialReactTable
            columns={columns}
            data={tableData}
            editingMode="modal"
            enableColumnOrdering
            enableEditing
            // enableFullScreenToggle={false} 
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={() => setValidationErrors({})}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(row)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                {isMobile ? "New" : "Add Hospital"}
              </Button>
            )}
          />
          <CreateNewHospitalModal
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            onSubmit={handleCreateNewRow}
            isMobile={isMobile}
          />
        </div>
      </ThemeProvider>
      <ToastContainer className="mt-5" /> {/* Add ToastContainer at the root level of UserDetails */} 
    </div>
  );
};

const CreateNewHospitalModal = ({ open, onClose, onSubmit, isMobile }) => {
  const [values, setValues] = useState({
    hospital_name: "",
    hospital_email: "",
    hospital_mobile: "",
    hospital_password: "",
    hospital_city: "",
    hospital_address: "",
    hospital_pincode: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!values.hospital_name.trim()) {
      errors.hospital_name = "Hospital Name is required";
      isValid = false;
    }

    if (!values.hospital_email.trim()) {
      errors.hospital_email = "Email is required";
      isValid = false;
    } else if (!/\b[A-Z0-9._%+-]+@gmail\.com\b/i.test(values.hospital_email)) {
      errors.hospital_email = "Enter a valid Gmail address";
      isValid = false;
    }

    if (!values.hospital_mobile.trim()) {
      errors.hospital_mobile = "Phone Number is required";
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(values.hospital_mobile)) {
      errors.hospital_mobile = "Invalid Phone Number";
      isValid = false;
    }

    if (!values.hospital_password.trim()) {
      errors.hospital_password = "Password is required";
      isValid = false;
    } else if (
      !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(values.hospital_password) ||
      values.hospital_password.length < 8
    ) {
      errors.hospital_password =
        "Password at least 8 characters long, it contain at least one upper& lower case, number, and special character ";
      isValid = false;
    }

    if (!values.hospital_city.trim()) {
      errors.hospital_city = "City is required";
      isValid = false;
    }

    if (!values.hospital_address.trim()) {
      errors.hospital_address = "Address is required";
      isValid = false;
    }

    if (!values.hospital_pincode.trim()) {
      errors.hospital_pincode = "Pincode is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(values);
      setValues({
        hospital_name: "",
        hospital_email: "",
        hospital_mobile: "",
        hospital_password: "",
        hospital_city: "",
        hospital_address: "",
        hospital_pincode: "",
      });
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" style={{ backgroundColor: "#1d212a", color: "#dfcd84" }}>Add Hospital</DialogTitle>
      <DialogContent style={{ backgroundColor: "#1d212a" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Hospital Name"
                value={values.hospital_name}
                onChange={(e) =>
                  setValues({ ...values, hospital_name: e.target.value })
                }
                error={!!errors.hospital_name}
                helperText={errors.hospital_name}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                value={values.hospital_email}
                onChange={(e) =>
                  setValues({ ...values, hospital_email: e.target.value })
                }
                error={!!errors.hospital_email}
                helperText={errors.hospital_email}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={values.hospital_mobile}
                onChange={(e) =>
                  setValues({ ...values, hospital_mobile: e.target.value })
                }
                error={!!errors.hospital_mobile}
                helperText={errors.hospital_mobile}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                // type="password"
                type={showPassword ? "text" : "password"} 
                value={values.hospital_password}
                onChange={(e) =>
                  setValues({ ...values, hospital_password: e.target.value })
                }
                error={!!errors.hospital_password}
                helperText={errors.hospital_password}
                required
                fullWidth
                variant="outlined"
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                value={values.hospital_city}
                onChange={(e) =>
                  setValues({ ...values, hospital_city: e.target.value })
                }
                error={!!errors.hospital_city}
                helperText={errors.hospital_city}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                value={values.hospital_address}
                onChange={(e) =>
                  setValues({ ...values, hospital_address: e.target.value })
                }
                error={!!errors.hospital_address}
                helperText={errors.hospital_address}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pincode"
                value={values.hospital_pincode}
                onChange={(e) =>
                  setValues({ ...values, hospital_pincode: e.target.value })
                }
                error={!!errors.hospital_pincode}
                helperText={errors.hospital_pincode}
                required
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem", backgroundColor: "#1d212a" }}>
        <Button onClick={onClose} style={{ color: "#dfcd84" }}>Cancel</Button>
        <Button
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
          sx={{ ml: "auto" }}
        >
          {isMobile ? "Create" : "Add Hospital"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Hospital;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import hero from "../../assets/images/hero.png";
import "../../assets/css/LoginPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSadCry, faFaceGrinBeam } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import css for react-toastify
import '../../assets/css/Toast.css'

const LoginPage = () => {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    user_email: "",
    user_password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await login(formData.user_email, formData.user_password);
      if (user) {
        toast.success("Successful Login"); // Notify success
        setTimeout(() => {
          if (user.user_role === "s_admin") {
            history.push("/dashboard", { user });
          } else if (user.user_role === "admin") {
            history.push("/admin", { user });
          } else if (user.user_role === "doctor") {
            history.push("/doctor", { user });
          } else if (user.user_role === "reception") {
            history.push("/reception", { user });
          } else {
            history.push("/user", { user });
          }
        }, 5000);
      } else {
       
        toast.error("Invalid Email or Password"); // Notify error
      }
    } catch (error) {
    
      toast.error("Invalid email or password"); // Notify error
    }
  };

  return (
    <div className="login-page">
      <ToastContainer className="toast-position" position="top-left" style={{color:"black"}} /> {/* ToastContainer for displaying notifications */}
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={6} className="user-animation">
            <div className="user-icon">
              <img className="hero-animated img-fluid" src={hero} alt="hero" />
            </div>
          </Col>
          <Col md={6}>
            <div className="tech2">
              <h1>
                <span>Medi</span>Monitor<span>X</span>
              </h1>
            </div>
            <div className="login-form">
              <h2 className="text-center mb-4">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="user_email">
                  <Form.Control
                    type="email"
                    name="user_email"
                    placeholder="Enter email"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                    style={{ marginBottom: "15px" }}
                  />
                </Form.Group>

                <Form.Group controlId="user_password" className="password-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="user_password"
                    placeholder="Password"
                    value={formData.user_password}
                    onChange={handleChange}
                    required
                    style={{ marginBottom: "15px" }}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faFaceSadCry : faFaceGrinBeam}
                    onClick={togglePasswordVisibility}
                    className="password-icon"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" block>
                  Login
                </Button>
                <div className="text-center mt-3">
                  <p>
                    Don't have an account? <Link to="/signup">Register</Link>
                  </p>
                </div>
              </Form>
              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;

import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import baseURL from "../config";

const LogoutConfirmation = ({ show, handleClose, handleLogout }) => {
  const history = useHistory();

  const handleLogoutClick = async () => {
    try {
      await axios.post(`${baseURL}/api/logout`);
      handleLogout();
      history.replace("/login");     // Use replace instead of push to prevent going back
    } catch (error) {
      console.error("Logout error:", error);
      // Handle error (e.g., show a notification)
    }
  };

  return (
    <Modal className="rounded-5" show={show} onHide={handleClose} centered >
      <Modal.Header className="text-light text-center" style={{backgroundColor:"#1d212a", borderBottom: "none"}} closeButton>
        <Modal.Title>Confirm Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-light text-center" style={{backgroundColor:"#1d212a"}}>Are you sure you want to Logout?</Modal.Body>
      <Modal.Footer className="text-light text-center" style={{backgroundColor:"#1d212a", borderTop: "none"}}>
        <Button variant="info" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleLogoutClick}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutConfirmation;

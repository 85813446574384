import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home/HomePage';
import Signup from './pages/Home/SignupPage';
import Login from './pages/Home/LoginPage';
import Logout from './components/Logout';
import SuperAdminDashboard from './pages/Home/SuperAdminDashboard';
import AdminDashboard from './pages/Home/AdminDashboard';
import UserDashboard from './pages/Home/UserDashboard';
import DoctorDashboard from './pages/Home/DoctorDashboard';
import ReceptionDashboard from './pages/Home/ReceptionDashboard';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext'; // import the UserProvider

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/dashboard" component={SuperAdminDashboard} />
              <PrivateRoute path="/admin" component={AdminDashboard} />
              <PrivateRoute path="/user" component={UserDashboard} />
              <PrivateRoute path="/doctor" component={DoctorDashboard} />
              <PrivateRoute path="/reception" component={ReceptionDashboard} />
              <Route path="/logout" component={Logout} />
            </Switch>
          </div>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;

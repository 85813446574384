import React, { useState, useEffect } from "react";
import "../../assets/css/MainPage.css";
import { useLocation } from "react-router-dom";
import NavButton from "../../components/NavButton";
import NavHeader from "../../components/NavHeader";
import SuperAdminItems from "../../utils/SuperAdminMenuItems";
import { useMediaQuery } from "@react-hook/media-query";
import Dashboard from "../SuperAdmin/DashboardPage";

const SuperAdminDashboard = () => {
  const [activeItem, setActiveItem] = useState(SuperAdminItems[0]?.name); // State to manage the active sidebar item
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to manage sidebar open/close
  const [isCardClicked, setIsCardClicked] = useState(false); // State to manage card click state

  const location = useLocation(); // Hook from react-router-dom to get current location
  const user = location.state?.user; // Access user data passed via route state

  const isMobile = useMediaQuery("(max-width: 768px)"); // Hook to detect mobile devices

  // Effect to close sidebar when the device is mobile
  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  // Handle sidebar item click
  const handleClick = (item, isMainNav) => {
    console.log("Handle click:", item);
    setActiveItem(item); // Set the clicked item as active
    setIsCardClicked(false); // Reset card click state
    if (isMobile) {
      setIsSidebarOpen(false); // Close sidebar on mobile after item click
    }
  };

  // Handle sidebar toggle button click
  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
    console.log("Toggling sidebar", isSidebarOpen ? "Closed" : "Opened");
  };

  // Handle card click in DashboardPage component
  const handleCardClick = () => {
    setIsCardClicked(true); // Set card click state to true
  };

  // Handle back button click in DashboardPage component
  const handleBackClick = () => {
    setIsCardClicked(false); // Reset card click state on back button click
  };

  return (
    <div className="container-fluid nav-padding-handling">
     
     <div>
     <NavHeader
        user={user}
        fixed="top"
        handleToggle={handleToggle}
        isSidebarOpen={isSidebarOpen}
      />
     </div>
     
     
      <div className="row g-0">
        <div className={`col-12 padding-adjust-sideBar ${isSidebarOpen ? "col-md-2" : "d-none"}`}>
          <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
            {SuperAdminItems.map((item) => (
              <div key={item.name}>
                <NavButton
                  onClick={() => handleClick(item.name, !item.items)}
                  name={item.name}
                  icon={item.icon}
                  isActive={activeItem === item.name}
                />
              </div>
            ))}
          </aside>
        </div>
        <div className={`col-12 padding-adjust-main-content ${isSidebarOpen ? "col-md-10" : "col-md-12"}`}>
          <div className="content ">
            <div className="main-content">
               {/* Render DashboardPage component if activeItem is "Dashboard" */}
               {activeItem === "Dashboard" && (
                  <Dashboard
                    handleCardClick={handleCardClick}
                    handleBackClick={handleBackClick}
                    isCardClicked={isCardClicked}
                  />
                )}
              <div className="content-wrapper">
                {SuperAdminItems.map(
                  (item) =>
                    activeItem === item.name && (
                      <div key={item.path}>
                        <div className="pt-2">{item.path}</div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
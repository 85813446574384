import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import baseURL from '../../config';
import { useAuth } from '../../context/AuthContext';

const UserDashboard = () => {
  const { user } = useAuth();
  const userId = user?.user_id;
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    console.log("Fetched user id:", userId);
    axios
      .get(`${baseURL}/user/notifications/${userId}`)
      .then((res) => {
        setNotifications(res.data.notifications); // Assuming res.data structure is { user: {}, notifications: [] }
      })
      .catch((err) => {
        console.log(err);
        setError('Your Inbox Is Empty');
      });
  };

  return (
    <div className=" d-flex justify-content-center">
      <div className=" pb-4 bg-dark text-light rounded-2 w-75">
        <Card>
          <Card.Header className="bg-secondary text-center">Notifications</Card.Header>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <div>
                <h5>Your Notifications Here</h5>
                <br/>
                {error ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  <ul>
                    {notifications.map((notification, index) => (
                      <li key={index}>{notification.message}</li>
                    ))}
                  </ul>
                )}
              </div>
              <footer className="blockquote-footer">
                Maintenance From <cite title="Source Title">MediMoniterx</cite>
              </footer>
            </blockquote>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default UserDashboard;
